import intlTelInput from 'intl-tel-input';

export default class IntlTelInput extends HTMLDivElement {

    constructor() {
        super();
        this.$input = this.querySelector('input');
    }

    connectedCallback() {

        intlTelInput(this.$input, {
            separateDialCode: true,
            initialCountry: "fr"
        });

    }

}